<template>
  <div class="about">
    <Banner :banner="banner" />
    <section class="first_section" id="train">
      <div class="row col-9 mx-auto">
        <Info :data="MCN" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            :src="require('../assets/hiSchool/img_hi8school_01.png')"
            alt=""
          />
        </div>
      </div>
    </section>
    <section id="subject">
      <div class="row col-9 mx-auto">
        <Info :data="multimedia" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            :src="require('../assets/hiSchool/img_hi8school_02.png')"
            alt=""
          />
        </div>
      </div>
    </section>
    <section id="teachers">
      <div class="row col-9 mx-auto">
        <Info :data="Planning" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            :src="require('../assets/hiSchool/img_hi8school_03.png')"
            alt=""
          />
        </div>
      </div>
    </section>
    <section id="lease">
      <div class="row col-9 mx-auto">
        <Info :data="rental" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            :src="require('../assets/hiSchool/img_hi8school_04.png')"
            alt=""
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner";
import Info from "@/components/Info";

export default {
  components: {
    Banner,
    Info,
  },
  data() {
    return {
      banner: {
        url: require("../assets/hiSchool/banner.jpg"),
        text: {
          title: "嗨學院",
          content:
            "翰成數位自 2005 年開發短影音業務至今超過 16年，我們累積了豐厚短影音製作、文案、拍攝、剪輯、配樂、標籤、影音大數據演算經驗。 2021 成立嗨學院，專攻直播、短影音教育培訓業務。我們擁有直播電商、短影音創作、造型穿搭、口條發音、文案企劃、拍攝剪輯、音樂創作、KOL 經紀等全台灣最堅強師資。嗨學院期許透過 HCE 直播產業基地，建構直播短影音專業人脈生態圈，號召有志邁向直播短影音夥伴共創未來最大影音商機。",
        },
      },
      MCN: {
        text: {
          title: "企業培訓",
          content: `由業界 10+ 年工作經驗的專業經理人，淬取過往經驗，整理出系統性、結構化的專業課程。協助企業培訓永續工作人才，厚植相關資訊與知能，提升企業競爭力與價值。`,
        },
      },
      multimedia: {
        text: {
          title: "數位課程",
          content: `從搜尋廣告到內容策略、行銷企劃、客戶開發、銷售溝通及顧客服務等精選課程，提供多種學習內容，協助拓展業務或發展職涯`,
        },
      },
      Planning: {
        text: {
          title: "專業師資",
          content: `專業師資實務講授，有效提升學員正確觀念，快速掌握客戶，提升即戰力！ 傳授創造業績方法`,
        },
      },
      rental: {
        text: {
          title: "多元空間租賃",
          content: `提供全方位空間，優質地段交通便利。多元的空間型態能啟發活絡更多的創意。場地面積約 50坪，可舉辦講座、課程、工作坊、發表會、展演等活動 ...。`,
        },
      },
    };
  },
};
</script>
<style lang="scss"></style>
